<template>
     <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
    <nav  class="nav nav-fill bottom-nav">
  <router-link to="/user/index" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/index') }" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <router-link to="/user/previewcard" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/previewcard') }" ><font-awesome-icon :icon="['fas', 'clinic-medical']" /><p class="bottom-title">Clinics</p></router-link>
  <router-link to="/user/community-groups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/community-groups') }" ><font-awesome-icon :icon="['fas', 'user-friends']" /><p class="bottom-title">Groups</p></router-link>              
  <!-- <router-link to="/user/publish" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Publish</p></router-link> -->
  
  <router-link to="/user/manage" v-if="publicData.updated_by == 'admin-customer' && publicData?.customer"  class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/user/manage') }">
          <img :src="publicData?.customer?.logo" alt="Customer Logo" style="width: 50px; height: 50px;" class="favicon-class" />
          <p class="bottom-title">Manage</p>
  </router-link>
        <div class="nav-link bottom-link footer-image-aftersignup" @click="toggleMoreMenu">
          <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
          <p class="bottom-title">More</p>
        </div>
  <!-- <router-link to="" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/request') }" ><font-awesome-icon :icon="['fas', 'list']" /><p class="bottom-title">Requests</p></router-link> -->
  </nav>
  <nav v-if="showMoreMenu" class="nav nav-fill  text-align text-center additional-items">
    <router-link to="/user/records" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/records') }" ><font-awesome-icon :icon="['fas', 'file-medical']" /><p class="bottom-title">Records</p></router-link>
    <router-link to="/user/library" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/library') }" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Library</p></router-link>
    </nav>
  </div>
</div>
</template>
<script>
import axios from 'axios';
export default {
    props: ["role"],
    data() {
    return {
      publicData:{},
      showMoreMenu: false, 
    }},
    created: function () {
      let publicUserId = localStorage.getItem('publicSignUpData')
      this.publicId = JSON.parse(publicUserId);
           if(this.publicId){
      this.getPublic();
     } 
  },
    methods: {
      toggleMoreMenu() {
      this.showMoreMenu = !this.showMoreMenu;
    },
    async getPublic(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
        .then((response) => {
          this.publicData = response.data
        });
    },
  currentPath(routePath) {
      this.$router.push(routePath);
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },

    }
}
</script>
<style>
    .additional-items {
  display: grid;
    position: absolute;
    bottom: 84px;
    right: 0;
}
.footer-image-aftersignup{
  margin: 0px !important;
  color: #000 !important;
  cursor: pointer;
  padding: 7px 14px;
}
.tab-activated {
  background-color:#00979e;
  color: #fff !important;
  margin: 0px;
}
</style>