<template>
    <div class="page-content w-100">
      <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Communities and Groups</p>
      </div>
    </div>
        <div class="mobie-setting-mainDiv">
            <a href="/user/doctorcommunities">
                <div class="SettingName">Communities of doctors</div>
            </a>
            <a href="/user/patientgroups">
                <div class="SettingName">Patient support groups</div>
            </a>
            
        </div>
    </div>
    <userfooter></userfooter>

</template>
<script>
import userfooter from "./user-footer.vue"
export default {
    components: {
    userfooter
    },
    props: ["role"],
    data() {
    return {

    }},
    created: function () {
  },
    methods: {
    }
}
</script>
<style>
.SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
}

.mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

</style>